import styled, { css } from 'styled-components';
import {
  space,
  color,
  width,
  fontSize,
  borders,
  borderColor,
  justifyContent,
  alignItems,
  flexWrap,
  textAlign,
  letterSpacing,
  style,
} from 'styled-system';
import defaultBg from './trianglify.png';
import theme from './theme';

const overflow = style({
  prop: 'overflow',
  cssProperty: 'overflow',
});

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => (props.columns ? props.columns : '1')}, 1fr);
  grid-gap: 30px;
  ${overflow};

  ${({ autoFit, columnMaxWidth }) =>
    autoFit &&
    css`
      grid-template-columns: repeat(auto-fit, minmax(${columnMaxWidth}, 1fr));
    `};
`;

export const PageHeader = styled.div`
  background: #ededed;
  max-height: ${props => (props.height ? props.height : '340px')};
  margin-bottom: 4rem;
  overflow: ${props => (props.overflow ? props.overflow : 'visible')};
  padding: ${props => (props.padding ? props.padding : '2rem 0')};
  position: relative;

  ${props =>
    props.dark &&
    css`
      background-image: url(${defaultBg});
      color: white;
    `};
`;

export const PageHeaderContent = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 999;

  @media (min-width: 768px) {
    h1 {
      font-size: 3rem;
    }
  }
`;

export const Text = styled.div`
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${borders}
  ${borderColor}
  ${textAlign}
  ${letterSpacing}
`;

export const Button = styled.button;

export const FlexRow = styled.div`
  display: flex;
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${borders}
  ${borderColor}
  ${justifyContent}
  ${alignItems}
  ${flexWrap}
`;

export const ServiceLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  ${color};
  ${space};
`;

export const Card = styled.div`
  background: white;
  box-shadow: 0 0 8px 0 rgba(37, 39, 41, 0.25);
  cursor: pointer;
`;

export const CardContent = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1rem;
`;

export const Filters = styled.ul`
  display: flex;
  color: ${props => (props.active ? theme.colors.primary : theme.colors.gray)};
  font-size: 0.875rem;
  font-weight: bold;
  list-style: none;
  padding: 0;
  text-transform: uppercase;
  ${space};

  li {
    cursor: pointer;
  }

  li:not(:last-child) {
    border-right: 1px solid ${theme.colors.grayMid};
    margin-right: 0.75rem;
    padding-right: 0.75rem;
  }
`;

export const Filter = styled.li`
  color: ${props => (props.active ? theme.colors.primary : theme.colors.gray)};
`;
