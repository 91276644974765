import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faArrowRight, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import theme from '../../styled/theme';
import Service from './Service';

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.75);
  color: white;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr 3fr 1fr;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
`;

const PlayButton = styled.div`
  background: #252729;
  border: 2px solid white;
  border-radius: 60px;
  cursor: pointer;
  display: grid;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  height: 120px;
  width: 120px;
  opacity: 0.9;
  grid-column: 2;
  grid-row: 2;
`;

const GoButton = styled(Link)`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 70px 70px;
  border-color: transparent transparent ${theme.colors.primary} transparent;
  position: relative;
  align-self: end;
  justify-self: end;
  grid-column: 3;
  grid-row: 3;
`;

const Arrow = styled(FontAwesomeIcon)`
  color: white;
  left: -25px;
  position: absolute;
  top: 35px;
`;

const ShopButton = styled(FontAwesomeIcon)`
  color: ${theme.colors.primary};
  cursor: pointer;
  grid-column: 1;
  grid-row: 3;
  align-self: center;
  justify-self: center;
`;

const CloseButton = styled.div`
  background: ${theme.colors.grayDark};
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  grid-column: 3;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
`;

class Actions extends Component {
  state = {
    initial: true,
    showStreams: false,
    showPurchase: false,
  }

  showStreamOptions = () => {
    this.setState({
      initial: false,
      showStreams: true,
      showPurchase: false,
    })
  }

  showPurchaseOptions = () => {
    this.setState({
      initial: false,
      showStreams: false,
      showPurchase: true,
    })
  }

  goBack = () => {
    this.setState({
      initial: true,
      showStreams: false,
      showPurchase: false,
    })
  }

  render() {
    const streamingPlatforms = this.props.album.acf.streaming_platforms;
    const purchasePlatforms = this.props.album.acf.purchase_platforms;

    const initial = (
      <>
        <PlayButton onClick={this.showStreamOptions}>
          <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faPlay} size="3x" />
        </PlayButton>
        <GoButton to={`/album/${this.props.album.slug}`}>
          <Arrow icon={faArrowRight} />
        </GoButton>
        <ShopButton icon={faShoppingCart} size="lg" onClick={this.showPurchaseOptions} />
      </>
    );

    const streamOptions = (
      <>
        <CloseButton onClick={this.goBack}>&times;</CloseButton>
        <List>
          {streamingPlatforms ? (
            streamingPlatforms.map(platform => <Service platform={platform} />)
          ) : (
            <h4>Este {this.props.album.acf.album_type} aún no esá disponible para streaming.</h4>
          )}
        </List>
      </>
    );

    const purchaseOptions = (
      <>
        <CloseButton onClick={this.goBack}>&times;</CloseButton>
        <List>
          {purchasePlatforms ? (
            purchasePlatforms.map(platform => <Service platform={platform} />)
          ) : (
            <h4>Este {this.props.album.acf.album_type} aún no esá disponible para comprar online.</h4>
          )}
        </List>
      </>
    );

    return (
      <Wrapper>
        {this.state.initial && initial}
        {this.state.showStreams && streamOptions}
        {this.state.showPurchase && purchaseOptions}
      </Wrapper>
    )
  }
};

export default Actions;
