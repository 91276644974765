import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import Album from '../components/Album';
import LatestAlbum from '../components/Album/LatestAlbum';
import { Filters, PageHeader, FlexRow } from '../styled';
import SearchBar from '../components/Filters';
import { createFilter } from '../util/Filter';
import flatten from 'flat';
import theme from '../styled/theme';

const unflatten = flatten.unflatten;

class Discography extends Component {
  state = {
    albums: [],
    filters: this.props.filters,
  }

  static defaultProps = {
    filters: [{
      property: 'title',
      value: '',
    }]
  }

  componentDidMount = () => {
    const albums = this.props.data.allWordpressWpAlbum.edges
      .map(album => flatten(album.node, { maxDepth: 2 }));

    this.setState({
      albums
    })
  }

  renderData = (data) => {
    if (data && data.length > 0) {
      const { filters } = this.state;

      if (Array.isArray(filters) && filters.length) {
        data = data.filter(createFilter(...filters));
      }

      return (
        data.map(album => <Column md={4} xs={6} key={album.id} mb="2rem"><Album data={unflatten(album)} /></Column>)
      );
    } else {
      return <div>No items found</div>;
    }
  }

  renderLoading = () => {
    return <div>Cargando...</div>
  }

  handleSearch = (e) => {
    this.setState({
      filters: [{
        property: 'title',
        value: e.target.value
      }, {
        property: 'acf.release_date',
        value: e.target.value
      }]
    })
  }

  filterByAlbumType = (target) => {
    this.setState({
      filters: [{
        property: 'acf.album_type',
        value: target
      }]
    })
  }

  render() {
    const { data } = this.props;
    const { albums } = this.state;

    const latestAlbum = data.allWordpressWpAlbum.edges.slice(0, 1).shift();

    const albumTypeFilters = [
      {
        title: 'Sencillos',
        target: 'sencillo',
      },
      {
        title: 'Discos',
        target: 'album',
      },
      {
        title: 'Todos',
        target: '',
      }
    ]

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${data.wordpressPage.title} - Eddy Herrera`} />
        <PageHeader dark>
          <Container>
            <Row>
              <Column sm={12}>
                <LatestAlbum album={latestAlbum.node} />
              </Column>
            </Row>
          </Container>
        </PageHeader>
        <Container>
          <Row>
            <Column sm={12}>
              <h1>Discografía</h1>
              <FlexRow justifyContent="space-between" mb="1rem" flexWrap="wrap">
                <Filters>
                  {albumTypeFilters.map(filter => (
                    <li key={filter.title} onClick={() => this.filterByAlbumType(filter.target)}>
                      {filter.title}
                    </li>
                  ))}
                </Filters>
                <SearchBar onChange={this.handleSearch} />
              </FlexRow>
            </Column>
          </Row>
          <Row>
            {this.state.albums ? this.renderData(albums) : this.renderLoading()}
          </Row>
        </Container>
      </Layout>
    );
  }
};

export const query = graphql`
  query DiscographyPageQuery {
    wordpressPage(slug: { eq: "discografia" }) {
      title
    }
    allWordpressWpAlbum(sort: { fields: acf___release_date, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          acf {
            release_date(formatString: "YYYY")
            album_type
            streaming_platforms {
              platform
              url
            }
            purchase_platforms {
              platform
              url
            }
          }
        }
      }
    }
  }
`;

export default Discography;
