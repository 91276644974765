import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import theme from '../../styled/theme';

const Input = styled.input`
  background: transparent;
  border: 0;
  font-size: 1rem;
  outline: 0;
  min-width: 225px;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Button = styled.button`
  background: transparent;
  border: 0;
  color: ${theme.colors.gray};
`;

const SearchBar = props => (
  <Wrapper>
    <Input type="text" onChange={props.onChange} placeholder="Buscar por título o por año" />
    <Button>
      <FontAwesomeIcon icon={faSearch} size="lg" onClick={props.onChange} />
    </Button>
  </Wrapper>
);

export default SearchBar;
