import React, { Component } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import theme from '../../styled/theme';
import Actions from './Actions';

const Wrapper = styled.div`
  background: white;
  box-shadow: 0 0 8px 0 rgba(37,39,41,0.25);
`;

const Details = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  padding: 1rem;

  * {
    margin: 0;
  }
`;

const Year = styled.h4`
  color: ${theme.colors.primary};
`;

const Type = styled.h4`
  color: ${theme.colors.gray};
  text-align: right;
  text-transform: uppercase;
`

const ImageWrapper = styled.div`
  position: relative;
`;

class Album extends Component {
  state = {
    hover: false,
  }

  render() {
    const { data } = this.props;
    const { release_date, album_type } = this.props.data.acf;

    return (
      <Wrapper onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
        <ImageWrapper>
          {data.featured_media && <Img sizes={data.featured_media.localFile.childImageSharp.sizes} />}
          {this.state.hover && <Actions album={this.props.data} />}
        </ImageWrapper>
        <Details>
          <Year>{release_date}</Year>
          <h3>{data.title}</h3>
          <Type>{album_type}</Type>
        </Details>
      </Wrapper>
    )
  }
}

export default Album;