import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import theme from '../../styled/theme';

const ServiceLink = styled.a`
  display: flex;
  align-items: center;
  color: ${props => (props.color ? props.color : 'white')};
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  text-decoration: none;
  text-transform: uppercase;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.colors.primary};
  min-width: 25px;
  margin-right: 0.25rem;
`;

const Service = props => {
  const { platform } = props;
  const icon = platform.platform === 'itunes' ? 'itunes-note' : platform.platform;

  return (
    <ServiceLink
      href={platform.url}
      target="_blank"
      rel="noopener nofollow"
      color={props.color}
      textTransform={props.textTransform}
    >
      <Icon icon={['fab', icon]} size="lg" />&nbsp;
      {platform.platform}
    </ServiceLink>
  );
};

Service.propTypes = {
  color: PropTypes.string,
};

export default Service;
