import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Button } from 'styled-bootstrap-components';

const Wrapper = styled.div`
  color: white;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  padding: 2rem 0;
`;

const Title = styled.h1`
  font-size: 2.25rem;
  letter-spacing: 2px;
  margin: 0;
`;

const Subtitle = styled.div`
  color: white;
  font-size: 0.85rem;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const LatestAlbum = ({ album }) => (
  <Wrapper>
    <Img sizes={album.featured_media.localFile.childImageSharp.sizes} />
    <div>
      <Subtitle>Más reciente {album.acf.album_type}</Subtitle>
      <Title>{album.title}</Title>
      <Button bg="primary" color="white" mt="1rem" lg>
        <Link to={`/album/${album.slug}`}>Leer más &rarr;</Link>
      </Button>
    </div>
  </Wrapper>
);

LatestAlbum.propTypes = {
  album: PropTypes.shape({
    title: PropTypes.string,
    acf: PropTypes.object,
    featured_media: PropTypes.object,
  }),
};

export default LatestAlbum;
